/**
* Routes for the whole site 
* @description : All the url routes are described here 
* @author : sathish@trymintly.com
*/
import React from 'react';
import { Navigate } from 'react-router-dom';
import { lazy } from '@loadable/component';
const MainLayout = lazy(()=> import('./layouts/MainLayout'));
const HomePageView = lazy(()=> import('./views/home'));
const BackgroundCheck = lazy(()=> import('./views/backgroundCheck'));
const Training = lazy(()=> import('./views/training'));
const TrainingInstitute = lazy(()=> import('./views/trainingInstitute'));
const InterviewLanding = lazy(()=> import('./views/interviewLanding'));
const PremiunHiring = lazy(()=> import('./views/premiumHiring'));
const LandingView = lazy(()=> import('./views/landing'));
const LandingJobsView = lazy(()=> import('./views/landingJobs'));
const AdminMainLayout = lazy(()=> import('./admin/layouts/MainLayout'));
const AdminDashboardLayout = lazy(()=> import('./admin/layouts/DashboardLayout'));
const AccountView = lazy(()=> import('./views/jobseeker'));
const JobseekerProfileSocial = lazy(()=> import('./views/jobseeker/social'));
const JobseekerDelete = lazy(()=> import('./views/jobseeker/delete'));
const EmployerAccountView = lazy(()=> import('./views/employer'));
const SuccessView = lazy(()=> import('./views/success'));
const CancelView = lazy(()=> import('./views/cancel'));
const DashboardView = lazy(()=> import('./views/jobseeker/dashboard'));
const EmployerDashboardView = lazy(()=> import('./views/employer/dashboard'));
const JobsView = lazy(()=> import('./views/search'));
const BrandJobsView = lazy(()=> import('./views/brandjobs'));
const WeeklyJobs = lazy(()=> import('./views/search/weekly'));
const FaqView = lazy(()=> import('./views/faq'));
const AboutView = lazy(()=> import('./views/about'));
const TermsView = lazy(()=> import('./views/terms'));
const PrivacyView = lazy(()=> import('./views/privacy'));
const CompaniesView = lazy(()=> import('./views/companies'));
const LoginView = lazy(()=> import('./views/auth/LoginView'));
const LoginViewUser = lazy(()=> import('./views/login'));
const ForgotView = lazy(()=> import('./views/auth/forgot'));
const ResetView = lazy(()=> import('./views/auth/reset'));
const LogoutView = lazy(()=> import('./views/auth/logout'));
const EmailVerify = lazy(()=> import('./views/emailVerify'));
const NotFoundView = lazy(()=> import('./views/errors/common'));
const NotFoundViewEmployer = lazy(()=> import('./views/errors/employer'));
const AppliedJobsView = lazy(()=> import('./views/applied'));
const SavedJobsView = lazy(()=> import('./views/saved'));
const CalledHrView = lazy(()=> import('./views/applied/callhr'));
const AppliedUsersView = lazy(()=> import('./views/appliedUsers'));
const PostedJobs = lazy(()=> import('./views/postedJobs'));
const RegisterView = lazy(()=> import('./views/register'));
const SettingsView = lazy(()=> import('./views/settings/SettingsView'));
const SupportView = lazy(()=> import('./views/support'));
const DemoView = lazy(()=> import('./views/demo'));
const SubmitResume = lazy(()=> import('./views/resume'));
const PostJob = lazy(()=> import('./views/post'));
const PremiumJobs = lazy(()=> import('./views/premium'));
const Blockchain = lazy(()=> import('./views/blockchain'));
const PricingView = lazy(()=> import('./views/pricing'));
const PricingCommonView = lazy(()=> import('./views/pricing/common'));
const JobDetailsView = lazy(()=> import('./views/job'));
const CompanyDetailsView = lazy(()=> import('./views/company'));
const ResumeDetailsView = lazy(()=> import('./views/resumeView'));
const UserDetailsView = lazy(()=> import('./views/userView'));
const PaymentLogView = lazy(()=> import('./views/paymentLog'));
const AdminView = lazy(()=> import('./admin/views/home'));
const AdminUsersView = lazy(()=> import('./admin/views/users'));
const AdminUsersDetailsView = lazy(()=> import('./admin/views/users/details'));
const AdminGenerateLink = lazy(()=> import('./admin/views/users/generateLink'));
const AdminGeneratedLinks = lazy(()=> import('./admin/views/users/linksList'));
const AdminResumeGenerateView = lazy(()=> import('./admin/views/resume/generate'));
const AdminResumeView = lazy(()=> import('./admin/views/users/resume'));
const SalesAdminView = lazy(()=> import('./admin/views/sales'));
const AdminTrainingView = lazy(()=> import('./admin/views/training'));
const AdminAddTrainingView = lazy(()=> import('./admin/views/training/add'));
const AdminUploadTraining = lazy(()=> import('./admin/views/training/add/imageupload'));
const SalesAdminAddProspectView = lazy(()=> import('./admin/views/sales/add'));
const AdminNewsletterView = lazy(()=> import('./admin/views/newsletter'));
const AdminContactView = lazy(()=> import('./admin/views/contactUs'));
const AdminPagesListView = lazy(()=> import('./admin/views/pages/list'));
const AdminAddPagesListView = lazy(()=> import('./admin/views/pages/add'));
const AdminCategoriesListView = lazy(()=> import('./admin/views/categories/list'));
const AdminCategoriesAddView = lazy(()=> import('./admin/views/categories/add'));
const AdminCategoriesDetailsView = lazy(()=> import('./admin/views/categories/details'));
const AdminSkillsListView = lazy(()=> import('./admin/views/skills/list'));
const AdminSkillsAddView = lazy(()=> import('./admin/views/skills/add'));
const AdminSkillsDetailsView = lazy(()=> import('./admin/views/skills/details'));
const AdminJobsListView = lazy(()=> import('./admin/views/job/List'));
const AdminAppliedJobseekersList = lazy(()=> import('./admin/views/applied'));
const AdminAppliedJobsListView = lazy(()=> import('./admin/views/job/appliedJobs'));
const AdminAddJobsListView = lazy(()=> import('./admin/views/job/AddJob'));
const AdminUploadJobs = lazy(()=> import('./admin/views/job/upload'));
const AdminCompaniesListView = lazy(()=> import('./admin/views/companies'));
const AdminCompaniesDetailsView = lazy(()=> import('./admin/views/companies/details'));
const AdminAddCompaniesListView = lazy(()=> import('./admin/views/companies/AddCompaniesView'));
const AdminUploadImageCompanies = lazy(()=> import('./admin/views/companies/AddCompaniesView/imageupload'));
const AdminInterviewListView = lazy(()=> import('./admin/views/interview'));
const AdminAddInterviewView = lazy(()=> import('./admin/views/interview/AddEditInterview'));
const AdminLoginView = lazy(()=> import('./admin/views/auth/LoginView'));
const FactoryAdminLoginView = lazy(()=> import('./admin/views/auth/FactoryLoginView'));
const AdminResumeListView = lazy(()=> import('./admin/views/resume/List'));
const AdminAddResumeView = lazy(()=> import('./admin/views/resume/AddResume'));
const AdminFaqListView = lazy(()=> import('./admin/views/faq/ListView'));
const AdminAddFaqView = lazy(()=> import('./admin/views/faq/AddView/RegisterView'));
const AdminUpdateFaqView = lazy(()=> import('./admin/views/faq/EditView'));
const AdminAddFactoryView = lazy(()=> import('./admin/views/factoryWorkers/add'));
const AdminListFactoryView = lazy(()=> import('./admin/views/factoryWorkers/list'));
const AdminLandingPage = lazy(()=> import('./admin/views/landing/add'));
const AdminLandingList = lazy(()=> import('./admin/views/landing/list'));
const AdminLandingEdit = lazy(()=> import('./admin/views/landing/edit'));
const AdminHotUsersEmail = lazy(()=> import('./admin/views/hotUsers/sendEmail'));
const AdminHotUsersList = lazy(()=> import('./admin/views/hotUsers/list'));
const GoldRate = lazy(()=> import('./views/gold'));
const Interview = lazy(()=>import('./views/interview'));
const InterviewList = lazy(()=>import('./views/interview/list'));
const AdminIndustryListView = lazy(()=> import('./admin/views/industry/list'));
const AdminIndustryAddView = lazy(()=> import('./admin/views/industry/add'));
const AdminIndustryDetailsView = lazy(()=> import('./admin/views/industry/details'));
const AdminSubIndustryListView = lazy(()=> import('./admin/views/subindustry/list'));
const AdminSubIndustryAddView = lazy(()=> import('./admin/views/subindustry/add'));
const AdminSubIndustryDetailsView = lazy(()=> import('./admin/views/subindustry/details'));
const AdminUsersList = lazy(()=> import('./admin/views/admin/index'));
const AdminUsersDetails= lazy(()=> import('./admin/views/admin/details'));
const AdminUsersNew= lazy(()=> import('./admin/views/admin/adminnew'));
const routes = [
  {
    path: 'admin',
    element: <AdminDashboardLayout />,
    children: [
      { path: '', element: <AdminView /> },
      { path: 'admin-users', element: <AdminUsersList /> },
      { path: 'admin-users/details/:id', element: <AdminUsersDetails /> },
      { path: 'admin-users/new', element: <AdminUsersNew /> },
      { path: 'user', element: <AdminUsersView /> },
      { path: 'user/details/:id', element: <AdminUsersDetailsView /> },
      { path: 'user/generate-link/:id', element: <AdminGenerateLink /> },
      { path: 'user/link', element: <AdminGeneratedLinks /> },
      { path: 'submitted-resume', element: <AdminResumeView /> },
      { path: 'contact', element: <AdminContactView /> },
      { path: 'newsletter', element: <AdminNewsletterView /> },
      { path: 'pages', element: <AdminPagesListView /> },
      { path: 'pages/edit/:url', element: <AdminAddPagesListView /> },
      { path: 'pages/add', element: <AdminAddPagesListView /> },
      { path: 'categories', element: <AdminCategoriesListView /> },
      { path: 'categories/add', element: <AdminCategoriesAddView /> },
      { path: 'categories/edit/:id', element: <AdminCategoriesAddView /> },
      { path: 'categories/details/:id', element: <AdminCategoriesDetailsView /> },
      { path: 'skills', element: <AdminSkillsListView /> },
      { path: 'skills/add', element: <AdminSkillsAddView /> },
      { path: 'skills/edit/:id', element: <AdminSkillsAddView /> },
      { path: 'skills/details/:id', element: <AdminSkillsDetailsView /> },
      { path: 'industry', element: <AdminIndustryListView /> },
      { path: 'industry/add', element: <AdminIndustryAddView /> },
      { path: 'industry/edit/:id', element: <AdminIndustryAddView /> },
      { path: 'industry/details/:id', element: <AdminIndustryDetailsView /> },
      { path: 'sub-industry', element: <AdminSubIndustryListView /> },
      { path: 'sub-industry/add', element: <AdminSubIndustryAddView /> },
      { path: 'sub-industry/edit/:id', element: <AdminSubIndustryAddView /> },
      { path: 'sub-industry/details/:id', element: <AdminSubIndustryDetailsView /> },
      { path: 'companies', element: <AdminCompaniesListView /> },
      { path: 'companies/add', element: <AdminAddCompaniesListView /> },
      { path: 'companies/edit/:id', element: <AdminAddCompaniesListView /> },
      { path: 'companies/details/:id', element: <AdminCompaniesDetailsView /> },
      { path: 'companies/upload/:id', element: <AdminUploadImageCompanies /> }, 
      { path: 'training', element: <AdminTrainingView /> },
      { path: 'training/add', element: <AdminAddTrainingView /> },
      { path: 'training/edit/:id', element: <AdminAddTrainingView /> },
      { path: 'training/upload/:id', element: <AdminUploadTraining /> },
      { path: 'jobs', element: <AdminJobsListView /> },
      { path: 'jobs/add', element: <AdminAddJobsListView /> },
      { path: 'jobs/upload', element: <AdminUploadJobs /> },
      { path: 'jobs/edit/:id', element: <AdminAddJobsListView /> },
      { path: 'jobs/applied/:id', element: <AdminAppliedJobsListView /> },
      { path: 'resume', element: <AdminResumeListView /> },
      { path: 'resume/add', element: <AdminAddResumeView /> },
      { path: 'resume/edit/:id', element: <AdminAddResumeView /> },
      { path: 'resume/generate/:id', element: <AdminResumeGenerateView /> },
      { path: 'faq', element: <AdminFaqListView /> },
      { path: 'faq/edit/:url', element: <AdminUpdateFaqView /> },
      { path: 'faq/add', element: <AdminAddFaqView /> },
      { path: 'factory-workers/add', element: <AdminAddFactoryView /> },
      { path: 'factory-workers/', element: <AdminListFactoryView /> },
      { path: 'landing', element: <AdminLandingList /> },
      { path: 'landing/edit/:url', element: <AdminLandingEdit /> },
      { path: 'landing/add', element: <AdminLandingPage /> }, 
      { path: 'applied', element:<AdminAppliedJobseekersList />},  
      { path: 'sales/prospect', element:<SalesAdminView />},
      { path: 'sales/prospect/add', element:<SalesAdminAddProspectView />},
      { path: 'sales', element:<SalesAdminView />},
      { path: 'interview', element:<AdminInterviewListView />},
      { path: 'interview/add', element:<AdminAddInterviewView />},
      { path: 'interview/edit/:id', element:<AdminAddInterviewView />},
      { path: 'hot-users/send', element:<AdminHotUsersEmail />},
      { path: 'hot-users', element:<AdminHotUsersList />},
      { path: '*', element: <NotFoundView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [         
      { path: 'submit-resume', element: <SubmitResume /> },            
      { path: ':brand-jobs', element: <BrandJobsView /> },
      { path: '/', element: <AdminLoginView /> },           
      { path: '/logout', element: <LogoutView /> },           
      { path: '*', element: <NotFoundView />}
    ]
  }
];
export default routes;