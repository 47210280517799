import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';
const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: '#ffffff',
      paper: '#ffffff'
    },
    primary: {
      main: '#0874BC'
    },
    secondary: {
      main: '#6C7497'
    },
    error:{
      main:"#EE4040"
    },
    info:{      
      main:'#E3EBFD',
      contrastText:'#0874BC'
    },
    text: {
      primary: "#0071BC",
      secondary: '#47505c',
      dark:'#343434',
      lightDark:'#212121',
      grey:'#3D3D3D',
      lblue:'#445578',
      lightGrey:'#9A9EA6',
      subtitle:'#343434'
    }
  },
  shadows,
  typography
});
export default theme;