/**
 * Mintly index page
 * 
 * @description: Index page where the contents are implemented.
 * 
 * @author: sathish@trymintly.com
 * @path: https://www.trymintly.com
 * @package: ReactJs
 */
 import React from 'react';
 import ReactDOM from 'react-dom';
 import { BrowserRouter } from 'react-router-dom';
 import * as serviceWorker from './serviceWorker';
 import App from './App';
 window.$base_url = 'https://www.trymintly.com/api/';
 window.$site_url = 'http://localhost:3000';
 ReactDOM.render((<BrowserRouter><App /></BrowserRouter>), document.getElementById('root'));
 serviceWorker.unregister();
