export default {
  h1: {
    fontWeight: 700,
    fontSize: '2.5em',
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 700,
    fontSize: '2em',
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.5em',
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 700,
    fontSize: '1em',
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 700,
    fontSize: '0.83em',
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 700,
    fontSize: '0.67em',
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  }
};