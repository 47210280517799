import 'react-perfect-scrollbar/dist/css/styles.css';
import React,{Suspense} from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import './App.css';
const renderLoader = () => <></>;
const App = () => {
  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={renderLoader()}>{routing}</Suspense>
    </ThemeProvider>
  );
};

export default App;